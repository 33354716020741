import { render, staticRenderFns } from "./Departments.vue?vue&type=template&id=48019478"
import script from "./Departments.vue?vue&type=script&lang=js"
export * from "./Departments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/janustest/.jenkins/workspace/05_Client_Vue_Release_Pipeline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48019478')) {
      api.createRecord('48019478', component.options)
    } else {
      api.reload('48019478', component.options)
    }
    module.hot.accept("./Departments.vue?vue&type=template&id=48019478", function () {
      api.rerender('48019478', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Aggrid/CellEditor/Departments.vue"
export default component.exports